// ? buttonFunction ? //
import AccountSetting from "../../views/BTNaccountSetting"
import RoleSetting from "../../views/BTNroleSetting"
import AuthoritySetting from "../../views/BTNauthoritySetting"
import ManipulateRecord from "../../views/BTNmanipulateRecord"

// ? playerInfo ? //
import UserInfo from "../../views/playerInfo/userInfo"
import GameCalculate from "../../views/playerInfo/gameCalculate"
import UserList from "../../views/playerInfo/userList"
import DepositList from "../../views/playerInfo/depositList"
import Mailman from "../../views/playerInfo/mailman"
import MailSearching from "../../views/playerInfo/mailSearching"
import CustomerForm from "../../views/playerInfo/customerForm/"
import Announcement from "../../views/playerInfo/announcement"

//? gameReport ?//
import OperationReport from "../../views/gameReport/operationReport"
import ReportAI from "../../views/gameReport/reportAI"
import ReportWithoutAI from "../../views/gameReport/reportWithoutAI"

//? operation ?//
import concurrentOnlineUsers from "../../views/operation/concurrentOnlineUsers"
import concurrentUsersHistory from "../../views/operation/concurrentUsersHistory"
import serverPeriodSetting from "../../views/operation/serverPeriodSetting"
import gameIdSetting from "../../views/operation/gameIdSetting"
import versionList from "../../views/operation/versionList"
import ParameterSetting from "../../views/operation/ParameterSetting"
import ItemSetting from "../../views/operation/itemSetting"
import ItemDetails from "../../views/operation/itemDetails"
import OrderManagement from "../../views/operation/orderManagement"
import TextSending from "../../views/operation/textSending"
import ChessMultiplicationControl from "../../views/operation/chessMultiplicationControl"
import ItemConfig from "../../views/operation/itemConfig"
import UserItem from "../../views/operation/userItem"
import ShopClassConfig from "../../views/operation/shopClassConfig"
import ShopConfig from "../../views/operation/shopConfig"
import coupon from "../../views/operation/coupon"

//? eventManagement ?//
import EventSetting from "../../views/eventManagement/eventSetting"
import EventUserList from "../../views/eventManagement/eventUserList"
import PrizeLimit from "../../views/eventManagement/prizeLimit"
import PrizeWeight from "../../views/eventManagement/prizeWeight"
import DZBMissionEvent from "../../views/eventManagement/dzbMissionEvent"
import DZBMissionEventReport from "../../views/eventManagement/dzbMissionEventReport"
import DZBMissionIndividualSetting from "../../views/eventManagement/dzbMissionIndividualSetting"
import ArenaConfig from "../../views/eventManagement/arenaConfig"

//? backstageManagement ?//
import DZBMissionSetting from "../../views/backstageManagement/dzbMissionSetting"
import dzbMissionReport from "../../views/backstageManagement/dzbMissionReport"
import NewsTicker from "../../views/backstageManagement/newsTicker"
import BackstageMatchSys from "../../views/backstageManagement/backstageMatchSys"
import GemRatioConfig from "../../views/backstageManagement/gemRatioConfig"
import adSettingForFinalCalculate from "views/backstageManagement/adSettingForFinalCalculate/adSettingForFinalCalculate"
import robotCenter from "views/backstageManagement/robotCenter/robotCenter"

const dashboardRoutes = [
  {
    title: "系統設定",
    content: [
      {
        path: "/accountSetting",
        name: "帳號設定",
        icon: "",
        component: AccountSetting,
        layout: "/admin"
      },
      {
        path: "/authoritySetting",
        name: "權限設定",
        icon: "",
        component: AuthoritySetting,
        layout: "/admin"
      },
      {
        path: "/roleSetting",
        name: "角色設定",
        icon: "",
        component: RoleSetting,
        layout: "/admin"
      },
      {
        path: "/manipulateRecord",
        name: "操作紀錄",
        icon: "",
        component: ManipulateRecord,
        layout: "/admin"
      }
    ],
    redirect: 1
  },
  {
    title: "玩家資訊",
    content: [
      {
        path: "/userInfo",
        name: "用戶詳細資訊",
        icon: "nc-icon nc-chart-pie-35",
        component: UserInfo,
        layout: "/admin",
        state: 'TPlayerInfo'
      },
      {
        path: "/gameCalculate",
        name: "戰績列表",
        icon: "nc-icon nc-circle-09",
        component: GameCalculate,
        layout: "/admin",
        state: 'TPlayerInfo'
      },
      {
        path: "/userList",
        name: "用戶列表",
        icon: "",
        component: UserList,
        layout: "/admin",
        state: 'TPlayerInfo'
      },
      {
        path: "/depositList",
        name: "儲值訂單",
        icon: "",
        component: DepositList,
        layout: "/admin",
        state: 'TPlayerInfo'
      },
      {
        path: "/mailman",
        name: "信件派發",
        icon: "",
        component: Mailman,
        layout: "/admin",
        state: 'TPlayerInfo'
      },
      {
        path: "/mailSearching",
        name: "信件查詢",
        icon: "",
        component: MailSearching,
        layout: "/admin",
        state: 'TPlayerInfo'
      },
      
      {
        path: "/announcement",
        name: "公告",
        icon: "",
        component: Announcement,
        layout: "/admin",
        state: 'TPlayerInfo'
      }
    ],
    redirect: 0
  },
  {
    title: '玩家資訊',
    content: [
      {
        path: "/customerForm",
        name: "客服表單",
        icon: "",
        component: CustomerForm,
        layout: "/admin",
        state: 'TPlayerInfo'
      },
    ],
    redirect: 1
  },
  {
    title: '遊戲報表',
    content: [
      {
        path: "/reportWithoutAI",
        name: "運營報表",
        icon: "",
        component: ReportWithoutAI,
        layout: "/admin",
        state: 'TGameReport'
      },
      {
        path: "/reportAI",
        name: "運營報表_R",
        icon: "",
        component: ReportAI,
        layout: "/admin",
        state: "TGameReport"
      },
      {
        path: "/operationReport",
        name: "總運營報表",
        icon: "",
        component: OperationReport,
        layout: "/admin",
        state: 'TGameReport'
      }
    ],
    redirect: 0
  },
  {
    title: "營運管理",
    content: [
      {
        path: "/concurrentOnlineUsers",
        name: "當前在線人數",
        icon: "",
        component: concurrentOnlineUsers,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/concurrentUsersHistory",
        name: "歷史在線人數",
        icon: "",
        component: concurrentUsersHistory,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/serverPeriodSetting",
        name: "伺服器時段設定",
        icon: "",
        component: serverPeriodSetting,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/gameIdSetting",
        name: "遊戲設定",
        icon: "",
        component: gameIdSetting,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/chessMultiplicationControl",
        name: "暗棋倍數控制",
        icon: "",
        component: ChessMultiplicationControl,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/versionList",
        name: "版本類別表單",
        icon: "",
        component: versionList,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/parameterSetting",
        name: "參數配置管理",
        icon: "",
        component: ParameterSetting,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/itemSetting",
        name: "兌換商品設定",
        icon: "",
        component: ItemSetting,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/orderManagement",
        name: "兌換訂單處理",
        icon: "",
        component: OrderManagement,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/coupon",
        name: "兌換序號管理",
        icon: "",
        component: coupon,
        layout: "/admin",
        state: "TOperation"
      },
      {
        path: "/textSending",
        name: "簡訊發送",
        icon: "",
        component: TextSending,
        layout: '/admin',
        state: 'TOperation'
      },
      {
        path: "/itemConfig",
        name: "道具管理設定",
        icon: "",
        component: ItemConfig,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/userItem",
        name: "用戶背包管理",
        icon: "",
        component: UserItem,
        layout: "/admin",
        state: 'TOperation'
      },
      {
        path: "/shopClassConfig",
        name: "虛擬道具商品設定",
        icon: "",
        component: ShopClassConfig,
        layout: "/admin",
        state: 'TOperation'
      }      
    ],
    redirect: 0
  },
  {
    title: "兌換商品設定",
    content: [
      {
        path: "/itemDetails",
        name: "兌換商品細項",
        icon: "",
        component: ItemDetails,
        layout: "/admin",
        state: 'TOperation'
      }, 
      {
        path: "/shopConfig",
        name: "兌換商品設定細項",
        icon: "",
        component: ShopConfig,
        layout: "/admin",
        state: 'TOperation'
      }
    ],
    redirect: 1
  },
  {
    title: "鬥陣寶任務活動",
    content: [
      {
        path: "/dzbMissionEventReport",
        name: "鬥陣寶任務活動報表",
        icon: "",
        component: DZBMissionEventReport,
        layout: "/admin",
        state: 'TCampaign'
      }
    ],
    redirect: 1
  },
  {
    title: "鬥陣寶任務活動",
    content: [
      {
        path: "/dzbMissionIndividualSetting",
        name: "個別任務設定",
        icon: "",
        component: DZBMissionIndividualSetting,
        layout: "/admin",
        state: 'TCampaign'
      }
    ],
    redirect: 1
  },
  {
    title: "活動管理",
    content: [
      {
        path: "/eventSetting",
        name: "活動配置",
        icon: "",
        component: EventSetting,
        layout: "/admin",
        state: 'TCampaign'
      },
      {
        path: "/eventUserList",
        name: "活動用戶列表",
        icon: "",
        component: EventUserList,
        layout: "/admin",
        state: 'TCampaign'
      }, 
      {
        path: "/prizeLimit",
        name: "活動獎勵控制",
        icon: "",
        component: PrizeLimit,
        layout: "/admin",
        state: 'TCampaign'
      },
      {
        path: "/prizeWeight",
        name: "活動獎品配置",
        icon: "",
        component: PrizeWeight,
        layout: "/admin",
        state: 'TCampaign'
      },
      {
        path: "/dzbMissionEvent",
        name: "鬥陣寶任務活動",
        icon: "",
        component: DZBMissionEvent,
        layout: "/admin",
        state: 'TCampaign'
      },
      {
        path: "/arenaConfig",
        name: "競技場活動配置",
        icon: "",
        component: ArenaConfig,
        layout: "/admin",
        state: 'TCampaign'
      }
    ],
    redirect: 0
  },
  {
    title: "後台運營管理",
    content: [
      {
        path: "/dzbMissionSetting",
        name: "鬥陣寶任務管理",
        icon: "",
        component: DZBMissionSetting,
        layout: "/admin",
        state: 'TBackstage'
      },
      {
        path: "/gemRatioConfig",
        name: "鬥陣寶係數管理",
        icon: "",
        component: GemRatioConfig,
        layout: "/admin",
        state: 'TBackstage'
      },
      {
        path: "/dzbMissionReport",
        name: "鬥陣寶任務報表",
        icon: "",
        component: dzbMissionReport,
        layout: "/admin",
        state: 'TBackstage'
      },
      {
        path: "/newsTicker",
        name: "跑馬燈",
        icon: "",
        component: NewsTicker,
        layout: "/admin",
        state: 'TBackstage'
      },
      {
        path: "/backstageMatchSys",
        name: "後台配對系統",
        icon: "",
        component: BackstageMatchSys,
        layout: "/admin",
        state: 'TBackstage'
      },
      {
        path: "/robotCenter",
        name: "Robot Center",
        icon: "",
        component: robotCenter,
        layout: "/admin",
        state: 'TBackstage'
      },
      {
        path: "/adSettingForFinalCalculate",
        name: "結算廣告設定",
        icon: "",
        component: adSettingForFinalCalculate,
        layout: "/admin",
        state: 'TBackstage'
      }
    ],
    redirect: 0
  }
]

export default dashboardRoutes