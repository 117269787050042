import { getAuthToken } from "../../utils.js"
import { BaseUrl } from "../../../assets/config/urlForBuild"

export const getBaseInfo = () => {
  const token = getAuthToken()
  return fetch(`${BaseUrl}/serverEnv/robotCenter`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
  .catch(err => console.log('err: ', err))
}
