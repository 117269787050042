import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Stack from '@mui/material/Stack'
import { SearchButton, ResetButton, BasicRowTitle, TableContent, PageStyle } from "../../../middleware/utilityStyle"
import { sessionVerified } from "../../../middleware/Api/publicApi.js"
import errorCode from "../../../assets/config/errorCode.json"
import gameCategories from "../../../assets/config/gameCategories.json"
import gameType from "../../../assets/config/gameType.json"
import { getMatchStatus } from "../../../middleware/Api/backstageManagement/backstageMatchSysApi.js"
import { getBaseInfo } from "../../../middleware/Api/robotAPI/robotAPI"
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'


import webSocket from 'socket.io-client'
import {
  InputBarBg,
  InputBarRWD,
  Dropdown,
  BtnGroup,
} from "../../operation/concurrentOnlineUsers/styledComponent.js"

export default function RobotCenter() {
  const history = useHistory()

  const [panelInfo, setPanelInfo] = useState({})
  const [pageCounter, setPageCounter] = useState(0)
  const [page, setPage] = React.useState(1)
  const [gameID, setGameID] = useState("0")

  useEffect(() => {
    handleSearch()
  }, [])

  const handleChange = (e) => {
    setGameID(e.target.value)
    localStorage.setItem("backstageMatchGameID", e.target.value)
  }


  const handleSearch = () => {
    sessionVerified().then(db => {
      if (db.error === errorCode.CODE_NOT_MATCH.code) {
        alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
        history.push("/login")
        return
      }
      if (db.error === errorCode.SESSION_EXPIRED.code) {
        alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
        history.push("/login")
        return
      }
      return getBaseInfo()
    }).then(db=>{
      if (!db.data.data) return 
      if (db.error === errorCode.NULL_DATA.code) {
        alert(`※  ${errorCode.NULL_DATA.memo}`)
        return
      }
      if (db.error === errorCode.EXCEPTION.code) {
        alert(`※  ${errorCode.EXCEPTION.memo}`)
        return
      }
      console.log(db)
      if (db.error){
        alert(JSON.stringify(db.error))
        return
      }
      const {data} = db.data.data
      setPanelInfo(data)
    }).catch(error => console.log(JSON.stringify(error)))
  }



  const handleChangePage = (event, newPage) => {
    // sessionVerified().then(db => {
    //   if (db.error === errorCode.CODE_NOT_MATCH.code) {
    //     alert(`${errorCode.CODE_NOT_MATCH.memo}，請重新登入。v。`)
    //     history.push("/login")
    //     return
    //   }
    //   if (db.error === errorCode.SESSION_EXPIRED.code) {
    //     alert(`${errorCode.SESSION_EXPIRED.memo}。v。`)
    //     history.push("/login")
    //     return
    //   }
    //   setPage(newPage)
    // })
  }


  function gameCategory() {
    let gameList = []
    gameCategories.map((ele, index) => {
      gameList.push(<option value = {ele.gameID} key= {index}>{ele.gameName}</option>)
    })
    return gameList
  }

  return (
    <>
      <InputBarBg className="navbar navbar-light bg-light" style= {{marginBottom: "1rem"}}>
        <InputBarRWD>
          <div>
            <span>遊戲類型</span>
            <Dropdown
              value= {gameID}
              onChange= {e => { }}
            >
              <option value="0">全部</option>
              {gameCategory()}
            </Dropdown>
          </div>
        </InputBarRWD>

        <BtnGroup>
          <SearchButton onClick= {handleSearch}>搜索</SearchButton>
        </BtnGroup>
      </InputBarBg>
      <Accordion  defaultActiveKey="1">
      <Card >
        <Card.Header className="navbar navbar-light bg-light">
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Information of Setting
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="navbar navbar-light bg-light">
            {Object.keys(panelInfo).length &&panelInfo.setting?
            <Table striped bordered hover size="sm">
              <thead >
                <tr>
                  <th>Setting</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Games which deploy to</td>
                  <td>{panelInfo.setting.portalToGame.map((ele, index)=>{
                    const findName = gameCategories.find(s => +s.gameID === +ele)
                    if (findName)return index !== panelInfo.setting.portalToGame.length-2 ?
                    `${findName.gameName}, `:`${findName.gameName}`
                    return ""
                  })}</td>
                </tr>
                <tr>
                  <td>Offline when lower than:</td>
                  <td>${panelInfo.setting.poorLimit}</td>
                </tr>
                <tr>
                  <td>Time for on field over than: </td>
                  <td>{panelInfo.setting.regularCheck>1? `${panelInfo.setting.regularCheck} hours`: `${panelInfo.setting.regularCheck} hour`}</td>
                </tr>
                {
                  Object.keys(panelInfo.setting.TIMING_FOR).length?
                  Object.keys(panelInfo.setting.TIMING_FOR).map((ele,index)=>{
                    let title
                    let hours 
                    switch(+ele){
                      case 1:
                        title = "How long mail will be collected:"
                        hours = panelInfo.setting.TIMING_FOR[ele].timeDiff
                        break;
                      default:
                        alert(`TIMING_FOR does not have ${+ele}`)
                        return 
                    }
                    return <>
                        <tr>
                        <td>{title} </td>
                        <td>{hours >1?`${hours} hours`: `${hours} hour` }</td>
                      </tr>
                    </>
                  })
                  :null
                }
              </tbody>
            </Table>
            :"No info"}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      </Accordion>

      <table className="table table-hover table-bordered" style= {{textAlign: 'center'}}>
        <thead>
          <BasicRowTitle>
            <th scope="col">UID</th>
            <th scope="col">NickName</th>
            <th scope="col">Amount</th>
            <th scope="col">State</th>
            {/* <th scope="col">房間狀態</th> */}
          </BasicRowTitle>
        </thead>
        <tbody>
          {
            Array.isArray(panelInfo.details) && panelInfo.details.length > 0 ?
            panelInfo.details.map((ele, index)=> {
              return (
                <TableContent key= {index}>
                  <td>{ele.uid}</td>
                  <td>{ele.nickname}</td>
                  <td>{ele.gold}</td>
                  <td style={{color: ele.state?"green":"red"}}>{ele.state?"online":"offline"}</td>
                </TableContent>
              )
            })
            : <tr key= "noInfo"><td colSpan= "4">尚無資料</td></tr>
          }
        </tbody>
      </table>

      {
        Array.isArray([panelInfo]) && panelInfo.length ?
          <Stack spacing={2}>
            <PageStyle count={pageCounter}
              color="warning"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        : ''
      }
    </>
  )
}